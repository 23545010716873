import { ItemService, WorkflowService } from "../service";
import { useFormStore } from "../store";

export function getFormData(id, itemId) {
  const setFormData = useFormStore.getState().setFormData;
  ItemService.getItem(id, itemId)
    .then((response) => {
      if (response) {
        setFormData(response);
      }
    })
    .catch((err) => console.error(err.message));
}

export function getFormSchema(flowId) {
  const setFormSchema = useFormStore.getState().setFormSchema;
  WorkflowService.getLiveMetadata(flowId).then((data) => {
    if (data) {
      setFormSchema(data);
    }
  });
}
