import { create } from "zustand";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  isLoading: false,
  account_details: null,
  sidebarIsCollapsed: true,
};

const useAccountStore = create((set) => ({
  ...store,
  setLoading: (isLoading) => set(() => ({ isLoading: isLoading })),
  updateAccountDetails: (data) => set(() => ({ account_details: data })),
  setSidebarIsCollapsed: (sidebarIsCollapsed) =>
    set(() => ({ sidebarIsCollapsed: sidebarIsCollapsed })),
  reset: () => set({ ...store }),
}));

export { useAccountStore };
