import { TABLES } from "../constants/tables";
import { useAccountStore } from "../store";
import { clear, getItem } from "../utils";
import { showSuccess, showError } from "../widgets";

let baseUrl = `${window.location.origin}/v1`;
// let baseUrl = "http://localhost/v1"; // DEVELOPMENT

// const accountId = "tenant01";

// function init() {
//   if (window.location.protocol !== "https:") {
// baseUrl = "http://app.enegma.in/v1";
// baseUrl = "http://localhost/v1"; // DEVELOPMENT
// }
// baseUrl = `http://192.168.1.105:8010/proxy`;
// baseUrl = accountId ? baseUrl + "/" + accountId : baseUrl;
// }
// init();

export function get(url, options = {}, showToast, isSearch) {
  options.method = "GET";
  return createReq(url, options, showToast, isSearch);
}

export function post(url, options = {}, showToast) {
  options.method = "POST";
  options.body = JSON.stringify(options.body);
  return createReq(url, options, showToast);
}

export function put(url, options = {}, showToast) {
  options.method = "PUT";
  options.body = JSON.stringify(options.body);
  return createReq(url, options, showToast);
}

export function deleteReq(url, options = {}, showToast) {
  options.method = "DELETE";
  return createReq(url, options, showToast);
}

function createReq(url, options, showToast) {
  // append other header , tokens here
  const headers = Object.assign(
    {
      "Content-Type": options.headers?.["Content-Type"]
        ? options.headers["Content-Type"]
        : "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      // "ngrok-skip-browser-warning": "asdasd"
    },
    options.headers || {}
  );
  options.mode = "cors"; // allow browser for cross-origin fetch(values: cors, no-cors, same-origin)
  options.credentials = "include"; // include cookies and authorization header(values: same-origin, omit, include)
  options.headers = headers;
  let reqUrl;
  if (url.indexOf("http") === 0) {
    reqUrl = url;
  } else {
    reqUrl = getBaseUrl() + url;
  }

  return fetch(reqUrl, options)
    .then((response) => CheckError(response))
    .then((response) => {
      console.log(response);
      if (response.status === 200 && showToast) {
        showSuccess(response.message);
      } else if (showToast) {
        showError(response.message);
      }
      if (response.status === 200 && response.data) {
        return response.data;
      } else if (response.status === 200) {
        delete response.status;
        if (reqUrl.endsWith("/list")) {
          let _res = Object.keys(response).map((key) => response[key]);
          return _res;
        }
        return response;
      }
      return null;
    })
    .catch((error) => {
      if (error.status === 401) {
        // localStorage.removeItem("token");
        clear();
        window.location.assign(
          window.location.protocol + "//" + window.location.hostname + "/login"
        );
      }
      return Promise.reject(error);
    });
}

async function CheckError(response) {
  if (response.status >= 200 && response.status <= 299) {
    const res = await response.json();
    return { ...res, status: response.status };
  } else {
    return response.json().then((data) => {
      return Promise.reject(data);
    });
  }
}

function getBaseUrl() {
  // Retrieve accountId from account store
  // let account_details = useAccountStore.getState().account_details;
  let account_id = getItem(TABLES.ACCOUNT_ID);
  window.account_id = account_id;
  return account_id ? baseUrl + "/" + account_id : baseUrl;
}
