import { create } from "zustand";
import { ItemService } from "../service";
// import { useShallow } from 'zustand/react/shallow'

const store = {
  itemId: null,
  formSchema: null,
  formInstance: null,
  formData: null,
  releasingPO: null,
};

const useFormStore = create((set, get) => ({
  ...store,
  setItemId: (id) => set(() => ({ itemId: id })),
  setFormInstance: (data) => set(() => ({ formInstance: data })),
  setFormSchema: (data) => set(() => ({ formSchema: data })),
  setFormData: (data) => set(() => ({ formData: data })),
  addRow: (connected_model_id) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    let formInstance = get().formInstance;
    ItemService.createLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id
    ).then((res) => {
      console.log(res);
      let _currentRows = formInstance.getFieldValue(connected_model_id) || [];
      formInstance.setFieldValue(connected_model_id, [
        ..._currentRows,
        {
          id: res.id,
        },
      ]);
    });
  },
  deleteRow: (connected_model_id, connected_model_row_id) => {
    let _item_id = get().itemId;
    let _schema = get().formSchema;
    ItemService.deleteLineItem(
      _schema.metadataId,
      _item_id,
      connected_model_id,
      connected_model_row_id
    ).then((res) => {
      console.log(res);
    });
  },
  setReleasingPO: (releasingPO) => set(() => ({ releasingPO: releasingPO })),
  reset: () => set({ ...store }),
}));

export { useFormStore };
