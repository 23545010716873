export const ALL_FEATURES = {
  addUser: "add_user",
  editUser: "update_user",
  deleteUser: "delete_user",
  changeUserPassword: "change_user_password",
  adminDashboard: "admin_dashboard",
  workflowPublish: "workflow_publish",
  workflowChange: "workflow_change",
  formChange: "form_change",
  formPublish: "form_publish",
  moveTicket: "move_ticket",
  openTicket: "open_ticket",
  createTicket: "create_ticket",
};

export const ALL_ROLES = {
  super_admin: {
    id: "super_admin",
    name: "Super Admin",
    permissions: { ...ALL_FEATURES },
  },
  admin: {
    id: "admin",
    name: "Admin",
    permissions: {
      adminDashboard: "admin_dashboard",
      workflowPublish: "workflow_publish",
      workflowChange: "workflow_change",
      formChange: "form_change",
      formPublish: "form_publish",
      moveTicket: "move_ticket",
      openTicket: "open_ticket",
      createTicket: "create_ticket",
    },
  },
  user: {
    id: "user",
    name: "User",
    permissions: {
      moveTicket: "move_ticket",
      openTicket: "open_ticket",
      createTicket: "create_ticket",
    },
  },
};

// export const ROLES_MAPPED = {
//   super_admin: {
//     ...Roles,
//   },
//   user: {},
// };
